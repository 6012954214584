<template>
  <div id="singleLineInputField" v-if="isVisible">
    <div class="field">
      <label class="label">
        {{ field.label }}
        <span v-show="isRequired" class="has-text-danger">*</span>
        <span
          v-show="field.helpBlockText"
          class="icon right tooltip is-tooltip-left"
          :data-tooltip="field.helpBlockText"
        >
          <icon :icon="['fas', 'question-circle']" />
        </span>
      </label>
      <div class="control">
        <input
          :id="'field-'+field.id"
          :name="field.id"
          class="input"
          :class="{ 'is-danger': !valid }"
          type="text"
          v-model="fieldValue"
          :placeholder="field.placeholder"
          :required="isRequired"
          :disabled="!isPreview && isReadOnly"
          :readonly="isPreview"
          :minLength="field.range[0].value"
          :maxLength="field.range[1].value"
          :pattern="field.validationPattern || null"
          @keyup="emitChange($event)"
        />
      </div>
      <p class="help" :class="{ 'is-danger': !valid }" v-show="!valid">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import FieldsMixin from '@/components/mixins/FieldsMixin';

export default {
  name: 'SingleLineInputField',
  mixins: [FieldsMixin],
  props: {
    field: {
      type: Object
    }
  },
  inject: ['isFieldRequired', 'isFieldReadOnly'],
  data() {
    return {
      valid: true,
      errorMessage: ''
    };
  },
  computed: {
    fieldValue: {
      get() {
        return this.field.value;
      },
      set(value) {
        this.field.value = value;
      }
    },
    isRequired() {
      return this.isFieldRequired(this.field);
    },
    isReadOnly() {
      // isDisabled is coming from the Field Mixin and dependencies checks.
      return this.isFieldReadOnly(this.field) || this.isDisabled;
    }
  },
  methods: {
    validate() {
      const input = this.$el.querySelector('input');
      this.valid = input.checkValidity();
      this.errorMessage = input.validationMessage;
      return this.valid;
    }
  }
};
</script>

<style lang="scss" scoped>
#singleLineInputField {
  @extend %fieldContainer;
}
</style>
