export default {
  data() {
    return {
      isVisible: false,
      isDisabled: true,
      lastActiveDependencyId: null
    };
  },
  watch: {
    isVisible(newValue) {
      // Watch if field is visible and has a value so we can emit
      // a change for possible fields that depend on this value.
      if (newValue && this.field?.selectedOptions?.length) {
        this.emitChange();
      }
    },
    isDisabled(newValue) {
      // Watch if field is enabled and has a value so we can emit
      // a change for possible fields that depend on this value.
      if (newValue && this.field?.selectedOptions?.length) {
        this.emitChange();
      }
    }
  },
  computed: {
    isPreview() {
      // Means user is previwing the record and not preforming an edit.
      return this.$route?.query?.preview ?? false;
    }
  },
  methods: {
    clearValidity() {
      this.valid = true;
      this.errorMessage = '';
    },
    emitChange(evt) {
      // If user change its value, record is lost.
      if (this.field.hasRecord) {
        this.field.hasRecord = null;
      }
      this.$emit('fieldValueHasChanged', {
        field: this.field
      });
    },
    resetFieldValue() {
      // Here we are resetting the value, to hide fields that depends on it
      // BUT, what if user set a default value when building the form?
      // for now that value is lost., should we recover it?
      if (this.activeOptions) {
        this.activeOptions = this.field.options;
        this.fieldValue = [];
      } else if (this.field.selectedOption) {
        this.fieldValue = [];
      }
      if (this.field.value) {
        this.fieldValue = '';
      }
    },
    /**
     * This function apply dependencies in the case they were updated.
     * @param {Object} field The field who updated dependencies.
     * @returns undefined
     */
    applyDependencies({
      field = null
    } = {}) {
      // If the field who started the action is the same than the
      // one trying to handle dependencies, then, nothing to apply.
      if (field && field.id === this.field.id) {
        return;
      }
      // Dependencies were updated, lets see if the current field dependencies
      // were affected, if there are no dependencies then it remains visible.
      if (this.field?.dependencies?.length) {
        const shallowCopy = this.field.dependencies.slice();
        shallowCopy.forEach(dep => {
          // If the current dependency (dep) is not linked with the
          // field who started the action, then, nothing to apply.
          if (field && dep.selectedDepedencyField.id !== field.id) {
            return;
          }
          // If dependency is active then we should show or hide current field accordingly.
          if (dep.isActive) {
            // We store the last active dependency ID to later know if we
            // should hide it in the case it is turned off.
            this.lastActiveDependencyId = dep.id;
            this.isVisible = dep.value !== 'hide' || false;
            this.isDisabled = dep.value === 'disable' || false;
            // If it is active but not visible, reset its value to
            // hide possible fields that depends on it.
            // If visible, then apply possible custom dependency options.
            if (!this.isVisible) {
              this.resetFieldValue();
              this.emitChange();
            } else {
              // Try to apply dependency default options if field
              // does not have an active record.
              if (this.activeOptions && dep.options.length) {
                this.activeOptions = dep.options;
                if (!this.field.hasRecord) {
                  this.fieldValue = dep.options
                    .map(option => {
                      if (option.checked) {
                        return option.value;
                      }
                    })
                    .filter(value => typeof value !== 'undefined');
                }
              }
              if (
                !this.field.hasRecord &&
                this.field.selectedOption &&
                dep.selectedOption
              ) {
                this.fieldValue = dep.selectedOption;
              }
            }
          } else if (dep.id === this.lastActiveDependencyId) {
            // Here, if current dependency (dep) is the same as
            // the last active and it is not active anymore, it means
            // it was turned off, lets apply new status.
            this.lastActiveDependencyId = null;
            this.isVisible = dep.value === 'show' ? false : true;
            this.isDisabled = dep.value !== 'disable' ? true : false;
            // In the case it is not visible, reset its value to
            // hide possible fields that depends on it.
            if (!this.isVisible || this.isDisabled) {
              this.resetFieldValue();
              this.emitChange();
            }
          /* TODO: Temporarily disabled. This is original set up for show/hide.
          } else if (dep.value === 'hide') {
            // If current dependency (dep) is not active but has a value
            // of 'hide', then hide it.
            this.isVisible = false; */
          } else {
            // If current dependency (dep) is not active but has a value,
            // take the necessary actions.
            this.isVisible = dep.value === 'show' ? false : true;
            this.isDisabled = dep.value === 'enable' ? true : false;
          }
        });
      } else {
        this.isVisible = true;
        this.isDisabled = false;
      }
    }
  },
  beforeMount() {
    // At this point, if a record exist, every field has a record in it with
    // dependencies activated according to the data in the record.
    // We should start appliyng the possible active dependencies. Also we listen
    // for new possible changes in dependencies.
    this.applyDependencies();
    this.$root.$on('onDependenciesUpdate', this.applyDependencies.bind(this));
  }
};
